<template>
  <div class="ManageInvoices">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1>Votre facturation Eclerk</h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CDataTable
          addTableClasses="text-center"
          :items="invoicesRender"
          :fields="invoicesRenderFields"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #status="{item}">
          <td v-if="item.status == 'draft'" class="text-secondary">
            <strong>{{stripeInvoiceStatus(item.status)}}</strong>
          </td>
          <td v-if="item.status == 'open'" class="text-danger">
            <strong><CIcon name="cil-x-circle" /> {{stripeInvoiceStatus(item.status)}}</strong>
          </td>
          <td v-if="item.status == 'paid'" class="text-success">
            <strong> <CIcon name="cil-check-circle" /> {{stripeInvoiceStatus(item.status)}}</strong>
          </td>
          <td v-if="item.status == 'void'" class="text-secondary">
            <strong>{{stripeInvoiceStatus(item.status)}}</strong>
          </td>
          <td v-if="item.status == 'uncollectible'" class="text-secondary">
            <strong>{{stripeInvoiceStatus(item.status)}}</strong>
          </td>
        </template>


        <template #amount_due="{item}">
          <td>
            <span>{{formatThousandSeparatorNumber(parseFloat(item.amount_due/10).toFixed(2))}} €</span>
          </td>
        </template>

        <template #invoice_pdf="{item}">
          <td>
            <a :href="item.invoice_pdf" target="_blank"><CIcon name="cil-cloud-download"/> Télécharger votre facture</a>
            <div v-if="item.status != 'paid'">
              <small><strong class="text-danger">Un lien de paiement est accessible en bas de la facture</strong></small>
            </div>
          </td>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import stripeRenderMixins from '@/mixins/stripeRenderMixins'

import renderMixins from '@/mixins/renderMixins'

import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBossConnected = new APIBossConnected()


export default {
  name: 'ManageInvoices',
  components: {
    Loading,
  },
  mixins: [
    stripeRenderMixins,
    renderMixins
  ],
  data: function () {
    return {

      // ---- PAYMENT METHODS ---------
      isInvoicesLoading: false,
      invoices: [],
      invoicesRender: [],
      invoicesRenderFields: [
        { key: "status", label: "Statut", tdClass: 'ui-helper-center', sortable: true},
        { key: "period_end", label: "Date", tdClass: 'ui-helper-center', sortable: true},
        { key: "amount_due", label: "Montant (TTC)", tdClass: 'ui-helper-center'},
        { key: "invoice_pdf", label: "", tdClass: 'ui-helper-center'},
      ],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isInvoicesLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.listInvoices()
  },
  watch: {
    invoices: function (newInvoices) {
        if (newInvoices.length == 0) {
          this.invoicesRender = []
        }
        else {
          this.invoicesRender = []

          for (var i = 0; i < newInvoices.length; i++) {
            var temp_data = {
              'status': newInvoices[i].status,
              'period_end': this.$dayjs.unix(newInvoices[i].period_end).format('DD MMM YYYY'),
              'amount_due': parseFloat(newInvoices[i].amount_due)/10,
              'invoice_pdf': newInvoices[i].invoice_pdf
            }

            this.invoicesRender.push(temp_data)
          }
        }
      },
  },
  methods: {

    // ------------- Stripe functions -----------
    listInvoices() {
      this.isInvoicesLoading = true
      return apiBossConnected.listInvoices(this.token)
        // If the card is declined, display an error to the user.
        .then((result) => {
          this.invoices = result.data.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isInvoicesLoading = false
        })
    },
  }
}
</script>
